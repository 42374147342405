<template>
    <div class="dashboard-container">
        <el-col>
            <el-tabs v-model="tabs" @tab-click="changeTab">
                <el-tab-pane
                    name="0"
                    label="企业查询"
                    v-if="$hasBtn('sys:permissions:inspection:business:query')"
                ></el-tab-pane>
                <el-tab-pane
                    name="1"
                    label="车辆查询"
                    v-if="$hasBtn('sys:permissions:inspection:vehicle:query')"
                ></el-tab-pane>
            </el-tabs>
        </el-col>
        <el-card style="margin-top: 20px">
            <div v-if="tabs == 0">
                <Company :condition="totalCondition" ref="childMethod1" />
            </div>
            <div v-else-if="tabs == 1">
                <Vehicle :condition="totalCondition" ref="childMethod" />
            </div>
        </el-card>
    </div>
</template>

<script>
import Company from "./Company";
import Vehicle from "./Vehicle";

export default {
    name: "Index",
    components: {
        Company,
        Vehicle,
    },
    data() {
        return {
            tabs: 0,
            totalCondition: {
                areaCode: "",
                startTime: "",
                endTime: "",
                statisticalDimension: "",
                area: "",
            },
            courseCondition: {
                areaCode: "",
                startTime: "",
                endTime: "",
                courseName: "",
                statisticalDimension: "",
                statisticalGranularity: "",
            },
            comCondition: {
                name: "",
                startTime: "",
                endTime: "",
            },
            userCondition: {
                companyId: "",
                realName: "",
                phone: "",
            },
            tableNumber: 1,
            // wspath: 'ws://192.168.88.59:8088/ws',
            // wspath: 'ws://192.168.88.242:18088/ws',
            wspath: "wss://www.zdwy.online/ws",
            wsUserId: "",
        };
    },
    created() {
        this.wsUserId = JSON.parse(sessionStorage.UserDetail).id;
    },
    mounted() {
        // this.getWebSocket();
    },
    methods: {
        getRegionT(v) {
            this.totalCondition.areaCode = v;
        },
        getStartT(v) {
            this.totalCondition.startTime = v;
        },
        getEndT(v) {
            this.totalCondition.endTime = v;
        },
        getTypeT(v) {
            this.totalCondition.statisticalDimension = v;
        },
        getLevelT(v) {
            this.totalCondition.area = v;
        },
        getRegionCo(v) {
            this.courseCondition.areaCode = v;
        },
        getStartCo(v) {
            this.courseCondition.startTime = v;
        },
        getEndCo(v) {
            this.courseCondition.endTime = v;
        },
        getTypeCo(v) {
            this.courseCondition.statisticalDimension = v;
        },
        getNameCo(v) {
            this.courseCondition.courseName = v;
        },
        getGranCo(v) {
            this.courseCondition.statisticalGranularity = v;
        },
        getCompanyC(v) {
            if (v) {
                this.comCondition.name = v;
            } else {
                this.comCondition.name = "";
            }
        },
        getDateC(v) {
            this.comCondition.startTime = v;
        },
        getEndC(v) {
            this.comCondition.endTime = v;
        },
        getCompanyU(v) {
            this.userCondition.companyId = v;
        },
        getNameU(v) {
            this.userCondition.realName = v;
        },
        getPhoneU(v) {
            this.userCondition.phone = v;
        },
        getTableNum(v) {
            this.tableNumber = v;
        },
        changeTab(v) {
            this.tableNumber = 1;
        },
        getWebSocket() {
            var that_ = this;
            if (typeof WebSocket === "undefined") {
                this.$message.warning("您的浏览器不支持socket");
            } else {
                // 实例化socket
                this.socket = new WebSocket(that_.wspath);
                // 监听socket连接
                this.socket.onopen = this.open;
                // 监听socket错误信息
                this.socket.onerror = this.error;
                // 监听socket消息
                this.socket.onmessage = this.getMessage;
                // 监听socket关闭
                // this.socket.onclose = this.close
            }
        },
        open() {
            this.send();
        },
        error() {
            console.log("WebSocket连接错误");
        },
        getMessage(msg) {
            var dataList = JSON.parse(msg.data);
            console.log(dataList);
            if (dataList.data && dataList.data.type == 1) {
                this.$message.info(dataList.data.content);
                if (this.tabs == 0) {
                    this.$refs.childMethod1.getList();
                } else if (this.tabs == 1) {
                    this.$refs.childMethod2.getList();
                } else if (this.tabs == 2) {
                    this.$refs.childMethod.getList();
                } else if (this.tabs == 3) {
                    this.$refs.childMethod3.getList();
                }
            }
        },
        send() {
            var params = {
                userId: this.wsUserId,
                type: "web",
            };
            this.socket.send(JSON.stringify(params));
            console.log("WebSocket连接");
        },
        close() {
            console.log("socket已经关闭");
            this.getWebSocket();
        },
    },
};
</script>

<style scoped></style>
