<template>
    <div class="dashboard-container">
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @refresh="refreshDialog"
        />

        <el-card>
            <div class="flex-row-box flex-cont-center">
                <div class="pl-1 pr-1">
                    <span class="text-primary text-pad-right">日期</span>
                    <el-date-picker
                        size="mini"
                        v-model="tiemNow"
                        type="date"
                        placeholder="选择日期"
                        @change="changeTime"
                    ></el-date-picker>
                </div>
                <div v-if="companyType" class="pl-1 pr-1">
                    <span class="text-primary text-pad-right">公司名称</span>
                    <el-select
                        v-model="companyId"
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="changeCondition"
                    >
                        <el-option
                            v-for="(item, index) in company"
                            :key="index"
                            :value="item.id"
                            :label="item.companyName"
                        />
                    </el-select>
                </div>
                <div class="flex-1"></div>
                <div class="pr-1">
                    <vxe-button @click="shuaxin">刷新</vxe-button>
                </div>
                <div>
                    <vxe-button @click="defenseExport">导出Excel</vxe-button>
                </div>
            </div>
        </el-card>

        <el-card class="boxNowCont" style="margin-top: 20px">
            <el-row>
                <!-- <vxe-toolbar>
                <template v-slot:buttons>
                    <el-row type="flex" justify="between">
                        <el-col></el-col>
                        <el-col :span="1">
                            <vxe-button @click="shuaxin">刷新</vxe-button>
                        </el-col>
                        <el-col :span="1">
                            <vxe-button @click="defenseExport"
                                >导出Excel</vxe-button
                            >
                        </el-col>
                        <el-col :span="1"></el-col>
                    </el-row>
                </template>
            </vxe-toolbar> -->
                <vxe-toolbar style="height: 50px">
                    <template v-slot:buttons>
                        <div
                            style="
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                border: 1px solid #e6ebf5;
                                border-bottom: 0;
                                font-size: 16px;
                                font-weight: bold;
                                color: #000000;
                            "
                            class="text-center"
                        >
                            {{ companyName }} "一日三检" 统计表
                        </div>
                    </template>
                </vxe-toolbar>
                <vxe-toolbar style="height: 50px">
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                        font-size: 14px;
                                        font-weight: bold;
                                        border-right: 0;
                                        color: #000000;
                                    "
                                    class="text-center"
                                >
                                    查询日期
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                    "
                                    class="text-center"
                                >
                                    {{ dateNow }}
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                        border-left: 0;
                                        border-right: 0;
                                        font-size: 14px;
                                        font-weight: bold;
                                        color: #000000;
                                    "
                                    class="text-center"
                                >
                                    公司名称
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                    "
                                    class="text-center"
                                >
                                    {{ companyName }}
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-toolbar style="height: 50px">
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                        font-size: 14px;
                                        font-weight: bold;
                                        border-right: 0;
                                        color: #000000;
                                    "
                                    class="text-center"
                                >
                                    企业车辆数
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                    "
                                    class="text-center"
                                >
                                    {{ carNums }}
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                        font-size: 14px;
                                        font-weight: bold;
                                        color: #000000;
                                        border-left: 0;
                                        border-right: 0;
                                    "
                                    class="text-center"
                                >
                                    应检车辆数
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                    "
                                    class="text-center"
                                >
                                    {{ shouldCheck }}
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-toolbar style="height: 50px">
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                        font-size: 14px;
                                        font-weight: bold;
                                        border-right: 0;
                                        color: #000000;
                                    "
                                    class="text-center"
                                >
                                    全检车辆数
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                    "
                                    class="text-center"
                                >
                                    {{ allCheckCaras }}
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                        font-size: 14px;
                                        font-weight: bold;
                                        color: #000000;
                                        border-left: 0;
                                        border-right: 0;
                                    "
                                    class="text-center"
                                >
                                    未检查车辆数
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #e6ebf5;
                                    "
                                    class="text-center"
                                >
                                    {{ unCheckCars }}
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    show-header-overflow
                    highlight-hover-row
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="400"
                    row-id="ID"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                    >>
                    <vxe-table-column type="seq" width="60"></vxe-table-column>
                    <vxe-table-column title="车牌号" field="carNum" />
                    <vxe-table-column title="检查人" field="checkUser" />
                    <vxe-table-column title="手机号" field="phone" />
                    <vxe-table-column title="车牌号照片" width="120">
                        <template v-slot="{ row }">
                            <el-popover
                                v-if="row.carNumUrl"
                                placement="right"
                                trigger="hover"
                            >
                                <img
                                    :src="$fileUrl + row.carNumUrl"
                                    alt=""
                                    style="width: 500px; height: 500px"
                                />
                                <img
                                    slot="reference"
                                    :src="$fileUrl + row.carNumUrl"
                                    alt=""
                                    style="width: 100px; height: 100px"
                                />
                            </el-popover>
                            <img
                                v-else
                                :src="require('@/assets/user.png')"
                                alt=""
                                style="width: 100px; height: 100px"
                            />
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="检查时间" field="checkTime" />
                    <vxe-table-column title="检查状态" field="checkStatus" />
                    <vxe-table-column
                        title="检查结果"
                        field="checkResult"
                        show-header-overflow
                        show-overflow="title"
                        show-footer-overflow
                    />
                    <vxe-table-column
                        title="异常状态是否整改"
                        field="rectification"
                        show-header-overflow
                        show-overflow="title"
                        show-footer-overflow
                    />
                    <vxe-table-column title="查看明细" width="120">
                        <template #default="{ row }">
                            <template>
                                <el-link
                                    type="primary"
                                    @click="
                                        openDialog(
                                            {
                                                title:
                                                    '营运车辆“一日三检”安全行车日志',
                                                addOr: 'add',
                                            },
                                            row
                                        )
                                    "
                                    >查看</el-link
                                >
                            </template>
                        </template>
                    </vxe-table-column>
                </vxe-table>

                <div
                    v-if="carNumUrlShow"
                    style="
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100vh;
                        z-index: 99999;
                    "
                    @click="carNumUrlShow = false"
                >
                    <img
                        style="
                            width: 500px;
                            height: 500px;
                            display: block;
                            position: absolute;
                            left: 40%;
                            top: 20%;
                        "
                        :src="$fileUrl + carNumUrlShowUrl"
                    />
                </div>
                <div
                    v-if="imgListShow"
                    style="
                        width: 100%;
                        height: 100vh;
                        position: fixed;
                        left: 0;
                        top: 0;
                        z-index: 99999;
                    "
                    class="flex-row-box flex-cont-center flex-justify-center"
                >
                    <div
                        style="
                            width: 500px;
                            height: 500px;
                            background-color: #f7f7f7;
                            font-size: 16px;
                            box-sizing: border-box;
                            position: relative;
                        "
                    >
                        <div
                            class="flex-row-box flex-cont-center"
                            style="padding-top: 20px"
                        >
                            <div class="text-center flex-1">查看照片</div>
                            <i
                                @click="imgListShow = false"
                                class="el-icon-error"
                                style="
                                    color: #1890ff;
                                    padding-right: 20px;
                                    font-size: 20px;
                                "
                            ></i>
                        </div>
                        <div style="padding: 20px">
                            <span
                                class="pr-1"
                                style="width: 100px; display: inline-block"
                                >选择状态：</span
                            >
                            <el-select
                                v-model="statusId"
                                filterable
                                size="mini"
                                class="input searchInput"
                                @change="getCarSummary"
                            >
                                <el-option
                                    v-for="(item, index) in options"
                                    :key="index"
                                    :value="item.id"
                                    :label="item.value"
                                />
                            </el-select>
                        </div>
                        <div
                            style="
                                width: 100%;
                                height: 391px;
                                overflow-y: scroll;
                            "
                        >
                            <div
                                style="padding: 20px"
                                class="flex-row-box flex-cont-center"
                                v-for="(item, index) in appearanceUrl"
                                :key="index"
                            >
                                <div class="pr-1" style="width: 100px">
                                    {{ item.checkItemName }}：
                                </div>
                                <div style="width: 150px; height: 150px">
                                    <img
                                        @click="
                                            clickImgShowNow(
                                                item.inspectionImgUrl
                                            )
                                        "
                                        style="width: 100%; height: 100%"
                                        :src="$fileUrl + item.inspectionImgUrl"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="imgShowNow"
                            style="
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                            "
                        >
                            <img
                                @click="hideImgShowNow"
                                style="width: 100%; height: 100%"
                                :src="$fileUrl + imgShowNow"
                            />
                        </div>
                    </div>
                </div>
                <div style="padding:0 15px;">
                    <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
                </div>

                <div style="height:20px;"></div>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { bytesToFile } from "@/utils/handleByte";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import {
    activeDefense,
    warnTypeSelect,
    activeDefenseExport,
    company,
} from "@/api";
import Operate from "./Operate";
export default {
    name: "Index",
    components: {
        Operate,
    },
    data() {
        return {
            loading: false,
            condition: {
                releaseStatus: [],
                search: "",
                date: [],
                start: "",
                end: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            company: [],
            loginInfo: {},
            companyId: "",
            tiemNow: "",
            tiemYMD: "",
            warnType: [],
            companyType: false,
            dateNow: "",
            compilePercent: "",
            companyName: "",
            carNums: "",
            allCheckCaras: "",
            shouldCheck: "",
            options: [
                {
                    value: "出车前",
                    id: "0",
                },
                {
                    value: "收车后",
                    id: "2",
                },
            ],
            statusId: "0",
            imgListShow: false,
            appearanceUrl: [],
            tyreUrl: "",
            carNumUrlShow: false,
            carNumUrlShowUrl: "",
            imgShowNow: "",
            unCheckCars: "",
        };
    },
    created() {
        this.getCompany();
        this.getWarnType();
        if (this.$companyType == 0) {
            this.companyType = true;
        } else {
            this.companyType = false;
        }
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
            this.dialog.info.date = this.tiemYMD;
        },
        clickImgShow(row, num) {
            if (num == 1) {
                console.log(row.carNumUrl);
                this.carNumUrlShow = true;
                this.carNumUrlShowUrl = row.carNumUrl;
            } else {
                this.imgListShow = true;
                this.carId = row.carId;
                this.userCarId = row.userId;
                this.getCarSummary();
            }
        },
        changeCondition(v) {
            this.table.currentPage = 1;
            this.getList();
            this.getCarInspection();
            this.$refs.table.clearCheckboxRow();
        },
        shuaxin() {
            this.table.currentPage = 1;
            this.getList();
            this.getCarInspection();
            this.$refs.table.clearCheckboxRow();
        },
        defenseExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                "/car/server/escort/web/CarInspection/company/export",
                null,
                {
                    companyId: this.companyId,
                    date: this.tiemYMD,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        changeTime(v) {
            var y = v.getFullYear();
            var m =
                v.getMonth() + 1 >= 9
                    ? v.getMonth() + 1
                    : "0" + (v.getMonth() + 1);
            var d = v.getDate() >= 10 ? v.getDate() : "0" + v.getDate();
            this.tiemYMD = y + "-" + m + "-" + d;
            this.getList();
            this.getCarInspection();
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                "/car/server/escort/web/CarInspection/list",
                null,
                {
                    companyId: this.companyId,
                    pageSize: this.table.pageSize,
                    currentPage: this.table.currentPage,
                    date: this.tiemYMD,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCarInspection() {
            this.loading = true;
            this.$axiosReq(
                "/car/server/escort/web/CarInspection",
                null,
                {
                    companyId: this.companyId,
                    date: this.tiemYMD,
                },
                "get"
            )
                .then((res) => {
                    this.dateNow = res.data.date != null ? res.data.date : "--";
                    this.compilePercent =
                        res.data.unCheckCars != null
                            ? res.data.unCheckCars
                            : "--";
                    this.companyName =
                        res.data.companyName != null
                            ? res.data.companyName
                            : "--";
                    this.carNums =
                        res.data.carNums != null ? res.data.carNums : "--";
                    this.allCheckCaras =
                        res.data.allCheckCaras != null
                            ? res.data.allCheckCaras
                            : "--";
                    this.shouldCheck =
                        res.data.shouldCheck != null
                            ? res.data.shouldCheck
                            : "--";
                    this.unCheckCars =
                        res.data.unCheckCars != null
                            ? res.data.unCheckCars
                            : "--";
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        remove(info, row) {
            const id = [];
            if (info.del === "batch") {
                const arr = this.$refs.table.getCheckboxRecords();
                const arr1 = this.$refs.table.getCheckboxReserveRecords();
                const batchDel = arr.concat(arr1);
                for (const i of batchDel) {
                    id.push(i.id);
                }
                if (id.length === 0) {
                    this.$message.error("请勾选！！！");
                    return;
                }
                this.$confirm("确认删除吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(journalism, id, null, "delete")
                            .then((res) => {
                                this.$message.success(res.msg);
                                this.getList(this.condition.company);
                                this.getCarInspection();
                            })
                            .finally((e) => {
                                // id = []
                            });
                    })
                    .catch(() => {
                        this.$message.info("取消了删除！！！");
                    });
            } else {
                this.$confirm("确认删除吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            journalism + row.id,
                            null,
                            null,
                            "delete"
                        ).then((res) => {
                            this.$message.success(res.msg);
                            this.getList(this.condition.company);
                            this.getCarInspection();
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了删除！！！");
                    });
            }
        },
        getWarnType() {
            this.$axiosReq(warnTypeSelect, null, null, "get").then((res) => {
                if (res.data && res.data.length > 0) {
                    this.warnType = res.data;
                }
            });
        },
        getCarSummary(id, num) {
            this.$axiosReq(
                "/car/server/escort/web/v2.1/CarSummary/" + this.carId,
                null,
                {
                    companyId: this.companyId,
                    date: this.tiemYMD,
                    whileTravel: this.statusId,
                    userId: this.userCarId,
                },
                "get"
            ).then((res) => {
                console.log(JSON.stringify(res));
                this.appearanceUrl = res.data;
            });
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    currentPage: 1,
                    pageSize: 1000,
                },
                "get"
            ).then((res) => {
                if (res.data.content && res.data.content.length > 0) {
                    this.company = res.data.content;
                    this.companyId = this.company[0].id;
                    this.getList();
                    this.getCarInspection();
                }
            });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
            this.getCarInspection();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
            this.getCarInspection();
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
        clickImgShowNow(url) {
            this.imgShowNow = url;
        },
        hideImgShowNow() {
            this.imgShowNow = "";
        },
    },
};
</script>

<style scoped></style>
