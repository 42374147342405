<template>
    <div class="dashboard-container">
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @refresh="refreshDialog"
        />
        <el-card>
            <div class="flex-row-box flex-cont-center">
                <div
                    class="pl-1 pr-1"
                    style="padding-top: 6px; margin-right: 20px"
                >
                    <span class="text-primary text-pad-right">车牌号:</span>
                    <el-input
                        v-model="carNum"
                        size="mini"
                        style="width: 200px"
                        class="input searchInput"
                        @input="changeCondition('2')"
                    />
                </div>
                <div
                    v-if="companyType"
                    class="pl-1 pr-1"
                    style="padding-top: 6px; margin-right: 20px"
                >
                    <span class="text-primary text-pad-right">公司名称:</span>
                    <el-select
                        v-model="companyId"
                        clearable
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="changeCondition('1')"
                    >
                        <el-option
                            v-for="(item, index) in company"
                            :key="index"
                            :value="item.id"
                            :label="item.companyName"
                        />
                    </el-select>
                </div>
                <div class="pl-1 pr-1" style="padding-top: 6px">
                    <span class="text-primary text-pad-right">线路:</span>
                    <el-select
                        v-model="lineId"
                        clearable
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="changeCondition('2')"
                    >
                        <el-option
                            v-for="(item, index) in lineList"
                            :key="index"
                            :value="item.id"
                            :label="item.lineName"
                        />
                    </el-select>
                </div>
            </div>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col></el-col>
                            <!-- <el-col :span="1">
								<vxe-button @click="shuaxin">刷新</vxe-button>
							</el-col> -->
                            <el-col :span="1">
                                <vxe-button v-if="$hasBtn('sys:permissions:report:inspection:gather:import')" @click="defenseExport"
                                    >导出Excel</vxe-button
                                >
                            </el-col>
                            <el-col :span="1"></el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    show-header-overflow
                    highlight-hover-row
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="530"
                    row-id="ID"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                    >>
                    <vxe-table-column type="seq" width="60"></vxe-table-column>
                    <vxe-table-column title="车牌号" field="carNum" />
                    <vxe-table-column title="车型" field="carModelName" />
                    <vxe-table-column title="所属线路" field="carLine" />
                    <vxe-table-column
                        title="所属公司"
                        field="companyName"
                        show-header-overflow
                        show-overflow="title"
                        show-footer-overflow
                    />
                    <vxe-table-column title="购置时间" field="purchaseTime" />
                    <vxe-table-column
                        title="上次年检时间"
                        field="lastTimeAsTime"
                    />
                    <vxe-table-column
                        title="下次年检时间"
                        field="nextTimeAsTime"
                    />
                    <vxe-table-column title="状态">
                        <template #default="{ row }">
                            <template>
                                <div v-if="row.statu === 0">在用</div>
                                <div v-if="row.statu == 1">停用</div>
                                <div v-if="row.statu == -1">报废</div>
                            </template>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="三检记录" width="120">
                        <template #default="{ row }">
                            <template>
                                <el-link
                                    type="primary"
                                    @click="
                                        openDialog(
                                            {
                                                title:
                                                    '营运车辆“一日三检”安全行车日志',
                                                addOr: 'add',
                                            },
                                            row
                                        )
                                    "
                                    >查看</el-link
                                >
                            </template>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { bytesToFile } from "@/utils/handleByte";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { company } from "@/api";
import Operate from "./Operate";
export default {
    name: "Index",
    components: {
        Operate,
    },
    data() {
        return {
            loading: false,
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            company: [],
            companyId: "",
            tiemYMD: "",
            companyType: false,
            lineList: [],
            lineId: "",
            carNum: "",
        };
    },
    created() {
        this.getCompany();

        if (this.$companyType == 0) {
            this.companyType = true;
        } else {
            this.companyType = false;
        }
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
            this.dialog.row.carId = row.id;
        },
        changeCondition(v) {
            this.table.currentPage = 1;
            this.getList();
            this.$refs.table.clearCheckboxRow();
            if (v == 1) {
				this.lineId = '';
                this.getLine();
            }
        },
        shuaxin() {
            this.table.currentPage = 1;
            this.getList();
            this.$refs.table.clearCheckboxRow();
        },
        defenseExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                "/car/server/escort/web/CarInspection/Car/export",
                null,
                {
                    companyId: this.companyId,
                    carNumSearch: this.carNum,
                    lineId: this.lineId,
                    pageSize: this.table.pageSize,
                    currentPage: this.table.currentPage,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                "/car/server/escort/web/carManage",
                null,
                {
                    companyId: this.companyId,
                    carNumSearch: this.carNum,
                    lineId: this.lineId,
                    pageSize: this.table.pageSize,
                    currentPage: this.table.currentPage,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    currentPage: 1,
                    pageSize: 1000,
                },
                "get"
            ).then((res) => {
                if (res.data.content && res.data.content.length > 0) {
                    this.company = res.data.content;
                    this.companyId = this.company[0].id;
                    this.getList();
                    this.getLine();
                }
            });
        },
        getLine() {
            this.$axiosReq(
                "/car/server/escort/web/line/companyId",
                null,
                {
                    companyId: this.companyId,
                },
                "get"
            ).then((res) => {
                if (res.data && res.data.length > 0) {
                    this.lineList = res.data;
                } else {
                    this.lineList = [];
                }
            });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
    },
};
</script>

<style scoped></style>
