<template>
    <el-dialog
        :visible.sync="show"
        width="70%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div style="position: absolute; width: 100%; left: 0; top: 10px">
            <el-row>
                <el-col :span="4">
                    <vxe-button @click="defenseExport" style="margin-left: 20px"
                        >导出Excel</vxe-button
                    >
                </el-col>
                <el-col :span="16">
                    <div
                        style="
                            text-align: center;
                            padding-top: 5px;
                            font-weight: bold;
                            font-size: 18px;
                        "
                    >
                        {{ info.title }}
                    </div>
                </el-col>
                <el-col :span="4">
                    <div
                        style="
                            padding-right: 14px;
                            padding-top: 5px;
                            text-align: right;
                        "
                    >
                        <i
                            @click="close"
                            style="font-size: 24px"
                            class="el-icon-error"
                        ></i>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div>
            <el-row style="margin-bottom: 10px" align="middle">
                <el-col :span="4" style="padding-top: 5px"
                    >车牌号：{{ row.carNum }}</el-col
                >
                <el-col :span="4" style="padding-top: 5px"
                    >线路：{{ row.carLine }}</el-col
                >
                <el-col :span="5">
                    检查人：
                    <el-select
                        v-model="userId"
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="getCarSummary"
                    >
                        <el-option
                            v-for="(item, index) in userList"
                            :key="index"
                            :value="item.userId"
                            :label="item.userName"
                        />
                    </el-select>
                </el-col>
                <el-col :span="5">
                    行车状态：
                    <el-select
                        v-model="statusId"
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="getCarSummary"
                    >
                        <el-option
                            v-for="(item, index) in options"
                            :key="index"
                            :value="item.id"
                            :label="item.value"
                        />
                    </el-select>
                </el-col>
                <el-col :span="6" class="dateClass">
                    行程日期：
                    <el-date-picker
                        v-model="dateNow"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                        @change="getCarSummary"
                    ></el-date-picker>
                </el-col>
            </el-row>
            <vxe-table
                ref="table"
                border
                show-header-overflow
                highlight-hover-row
                resizable
                :auto-resize="true"
                :header-row-style="headerStyle"
                :row-style="rowStyle"
                align="center"
                class="vxe-table-element"
                height="530"
                row-id="ID"
                :data="carInspectionInfoVos"
            >
                <vxe-table-column title="检查项" field="inspectionItemName" />
                <vxe-table-column
                    title="检查结果"
                    field="inspectionStatusName"
                />
                <vxe-table-column title="检测照片" width="120">
                    <template v-slot="{ row, rowIndex }">
                        <el-popover
                            v-if="row.inspectionImgUrl"
                            placement="right"
                            trigger="hover"
                        >
                            <img
                                :src="$fileUrl + row.inspectionImgUrl"
                                alt=""
                                style="width: 500px; height: 500px"
                            />
                            <img
                                slot="reference"
                                :src="$fileUrl + row.inspectionImgUrl"
                                alt=""
                                style="width: 100px; height: 100px"
                            />
                        </el-popover>
                        <img
                            v-else
                            :src="require('@/assets/user.png')"
                            alt=""
                            style="width: 100px; height: 100px"
                        />
                    </template>
                </vxe-table-column>
                <vxe-table-column title="存在问题">
                    <template #default="{ row }">
                        <template>
                            <div>
                                {{
                                    row.rectificationExplain
                                        ? row.rectificationExplain
                                        : "--"
                                }}
                            </div>
                        </template>
                    </template>
                </vxe-table-column>
                <vxe-table-column title="整改结果">
                    <template #default="{ row }">
                        <template>
                            <div>
                                {{
                                    row.rectificationResultsName
                                        ? row.rectificationResultsName
                                        : "--"
                                }}
                            </div>
                        </template>
                    </template>
                </vxe-table-column>
                <vxe-table-column title="整改时间" field="modifyTime" />
                <vxe-table-column title="整改后照片" width="120">
                    <template v-slot="{ row, rowIndex }">
                        <el-popover
                            v-if="row.rectificationImgUrl"
                            placement="right"
                            trigger="hover"
                        >
                            <img
                                :src="$fileUrl + row.rectificationImgUrl"
                                alt=""
                                style="width: 500px; height: 500px"
                            />
                            <img
                                slot="reference"
                                :src="$fileUrl + row.rectificationImgUrl"
                                alt=""
                                style="width: 100px; height: 100px"
                            />
                        </el-popover>
                        <img
                            v-else
                            :src="require('@/assets/user.png')"
                            alt=""
                            style="width: 100px; height: 100px"
                        />
                    </template>
                </vxe-table-column>
            </vxe-table>
            <el-row style="margin-top: 10px" align="bottom">
                <el-col :span="2">检查执行人：</el-col>
                <el-col :span="5">
                    <img
                        v-if="signUrl"
                        style="width: 90px; height: 90px"
                        :src="$fileUrl + signUrl"
                    />
                </el-col>
                <el-col :span="16"></el-col>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
import { getYear, getMonth, getDay } from "@/utils/getDate";
import { bytesToFile } from "@/utils/handleByte";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
export default {
    name: "Operate",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            loading: false,
            show: this.dialog,
            options: [
                {
                    value: "出车前",
                    id: "0",
                },
                {
                    value: "行车中",
                    id: "1",
                },
                {
                    value: "收车后",
                    id: "2",
                },
            ],
            statusId: "0",
            carInspectionInfoVos: [],
            carId: "",
            carLine: "",
            carNum: "",
            signUrl: "",
            dateNow: "",
            userId: "",
            userList: [],
			inspectionId: ''
        };
    },
    created() {
        this.dateNow = getYear() + "-" + getMonth() + "-" + getDay();
        this.checkUser();
    },
    methods: {
        rowStyle,
        headerStyle,
        getCarSummary() {
            let that_ = this;
            this.carInspectionInfoVos = [];
            // if (!this.userId) {
            //     that_.$message.error("请选择检查人");
            //     return false;
            // }
            // if (!this.dateNow) {
            //     that_.$message.error("请选择行程日期");
            //     return false;
            // }
            this.$axiosReq(
                "/car/server/escort/web/CarInspection/detail",
                null,
                {
                    carId: that_.row.carId,
                    date: that_.dateNow,
                    status: that_.statusId,
                    userId: that_.userId,
                },
                "get"
            ).then((res) => {
                // console.log(JSON.stringify(res))
                this.carId = res.data.carId ? res.data.carId : "";
                this.carLine = res.data.carLine ? res.data.carLine : "";
                this.carNum = res.data.carNum ? res.data.carNum : "";
                this.signUrl = res.data.signUrl ? res.data.signUrl : "";
                this.inspectionId = res.data.inspectionId ? res.data.inspectionId : "";
                if (
                    res.data.carInspectionInfoVos &&
                    res.data.carInspectionInfoVos.length > 0
                ) {
                    this.carInspectionInfoVos = res.data.carInspectionInfoVos;
                }
            });
        },
        checkUser() {
            let that_ = this;
            this.$axiosReq(
                "/car/server/escort/web/CarInspection/Car/checkUser/" +
                    this.row.carId,
                null,
                null,
                "get"
            ).then((res) => {
                if (res.data && res.data.length > 0) {
                    that_.userList = res.data;
					if (res.data && res.data.length > 0) {
						that_.userId = res.data[0].userId;
					}
					that_.getCarSummary();
                }
            });
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
        defenseExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                "/car/server/escort/web/carManage/checkMsg/excel",
                null,
                {
                    id: this.inspectionId,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
    },
};
</script>

<style scoped></style>
